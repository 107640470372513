import './App.css';
import MyComponent from './MyComponent';

function App() {
  return (
    <div className="iamsuslo" style={{ position: 'relative', minHeight: '100vh' }}>
      <p
        style={{
          fontFamily: 'CustomFont',
          textAlign: 'center',
        }}
      >
        <MyComponent />
      </p>
      <p style={{ fontFamily: 'CustomFont', textAlign: 'center', fontSize: '14px'}}>
        По всем вопросам обращаться к менеджеру:
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          textAlign: 'center',
          fontSize: '22px',
          fontFamily: 'CustomFont'
        }}
      >
        <span>Баба Вера</span>
        <span>|</span>
        <a href="tel:+79819048907" style={{ textDecoration: 'none', color: 'blue' }}>
          +7-981-904-89-07
        </a>
      </div>
      <p style={{ textAlign: 'center', fontSize: 12, fontFamily: 'CustomFont' }}>Since 2004</p>

      {/* Crypto Wallets Footer */}
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          textAlign: 'center',
          backgroundColor: '#f8f9fa', // Light background for visibility
          padding: '10px 0',
          fontSize: '12px',
          fontFamily: 'CustomFont'
        }}
      >
        <p>
          <strong>BTC:</strong> bc1qhx38guelgryvm8cwr4kzs80mmc0affr5sv8vle
        </p>
        <p>
          <strong>ETH:</strong> 0xe8efFa2665445F3D64fc10cE5715CCd3781ce3A1
        </p>
        <p>
          <strong>SOL:</strong> E7zag7h9YNJwZVgpcm4CbytP9HgNmU5vXpSqfCddR6Dn
        </p>
      </div>
    </div>
  );
}

export default App;
