import React from "react";
import danya from './images/danya.png';

const MyComponent = function() {
    return (
        <div>
            <h1>А вы знаете эту бебру?</h1>
            <img src={danya} alt="Danya" width={400} height={400}></img>
        </div>
    );
};

export default MyComponent;